import _slicedToArray from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React from "react";
var __jsx = React.createElement;
import { useEffect } from 'react';
import { useUpdaterChainId, useLimitOrderRouter } from "../../hooks";
import { useAppDispatch } from "../hooks";
import { updateConfig, updateV6Config, updateLimitSwapPair, updateSpotSwapPair } from "./actions";
import exchangeRpc from "../../services/rpc/tokenlon";
import { useLimitSwapPair, useLimitSwapTokens, useLimitSwapTokensUpdater, useSpotSwapPair, useSpotSwapTokens, useSpotSwapTokensUpdater, useTokenlonV6ConfigUpdater, useSpotSwapRecommendTokensUpdater, useTokenlonV6NetworkConfig } from "./hooks";
import { WETH, ETH, USDT } from "../../constants/addresses";
import { compareAddress } from "../../utils/token";

var findTokenByAddress = (address, tokens) => {
  var lowercaseAddress = address.toLowerCase();

  for (var key in tokens) {
    if (key.toLowerCase() === lowercaseAddress) {
      return tokens[key];
    }
  }

  return undefined;
};

export var SpotSwapPairResetUpdater = () => {
  var dispatch = useAppDispatch();
  var chainId = useUpdaterChainId();

  var _useSpotSwapPair = useSpotSwapPair(),
      _useSpotSwapPair2 = _slicedToArray(_useSpotSwapPair, 2),
      inputToken = _useSpotSwapPair2[0],
      outputToken = _useSpotSwapPair2[1];

  var tokens = useSpotSwapTokens(chainId);

  var _useTokenlonV6Network = useTokenlonV6NetworkConfig(),
      networkDefaultPair = _useTokenlonV6Network.defaultPair;

  var defaultPair = networkDefaultPair !== null && networkDefaultPair !== void 0 ? networkDefaultPair : [{
    address: ETH[chainId]
  }, {
    address: USDT[chainId]
  }];
  useEffect(() => {
    if (!tokens || !Object.keys(tokens).length) return;
    var isNoInput = !inputToken || inputToken.chainId !== chainId || !tokens[inputToken.address];
    var isNoOutput = !outputToken || outputToken.chainId !== chainId || !tokens[outputToken.address];

    if (isNoInput || isNoOutput) {
      var defaultInputToken;
      var defaultOutputToken;

      if (isNoInput && isNoOutput) {
        defaultInputToken = findTokenByAddress(defaultPair[0].address, tokens);
        defaultOutputToken = findTokenByAddress(defaultPair[1].address, tokens);
      } else if (isNoInput) {
        var targetAddress = compareAddress(defaultPair[0].address, outputToken.address) ? defaultPair[1].address : defaultPair[0].address;
        defaultInputToken = findTokenByAddress(targetAddress, tokens);
        defaultOutputToken = outputToken;
      } else {
        var _targetAddress = compareAddress(defaultPair[0].address, inputToken.address) ? defaultPair[1].address : defaultPair[0].address;

        defaultInputToken = inputToken;
        defaultOutputToken = findTokenByAddress(_targetAddress, tokens);
      }

      dispatch(updateSpotSwapPair({
        inputToken: defaultInputToken,
        outputToken: defaultOutputToken
      }));
    }
  }, [chainId, tokens, inputToken === null || inputToken === void 0 ? void 0 : inputToken.chainId, outputToken === null || outputToken === void 0 ? void 0 : outputToken.chainId, inputToken === null || inputToken === void 0 ? void 0 : inputToken.address, outputToken === null || outputToken === void 0 ? void 0 : outputToken.address, JSON.stringify(defaultPair)]);
  return null;
};
export var LimitSwapPairResetUpdater = () => {
  var dispatch = useAppDispatch();
  var chainId = useUpdaterChainId();

  var _useLimitSwapPair = useLimitSwapPair(),
      _useLimitSwapPair2 = _slicedToArray(_useLimitSwapPair, 2),
      inputToken = _useLimitSwapPair2[0],
      outputToken = _useLimitSwapPair2[1];

  var tokens = useLimitSwapTokens(chainId);
  var defaultPair = [{
    address: WETH[chainId]
  }, {
    address: USDT[chainId]
  }];
  useEffect(() => {
    if (!tokens || !Object.keys(tokens).length) return;
    var isNoInput = !inputToken || inputToken.chainId !== chainId || !tokens[inputToken.address];
    var isNoOutput = !outputToken || outputToken.chainId !== chainId || !tokens[outputToken.address];

    if (isNoInput || isNoOutput) {
      var defaultInputToken;
      var defaultOutputToken;

      if (isNoInput && isNoOutput) {
        defaultInputToken = findTokenByAddress(defaultPair[0].address, tokens);
        defaultOutputToken = findTokenByAddress(defaultPair[1].address, tokens);
      } else if (isNoInput) {
        var targetAddress = compareAddress(defaultPair[0].address, outputToken.address) ? defaultPair[1].address : defaultPair[0].address;
        defaultInputToken = findTokenByAddress(targetAddress, tokens);
        defaultOutputToken = outputToken;
      } else {
        var _targetAddress2 = compareAddress(defaultPair[0].address, inputToken.address) ? defaultPair[1].address : defaultPair[0].address;

        defaultInputToken = inputToken;
        defaultOutputToken = findTokenByAddress(_targetAddress2, tokens);
      }

      dispatch(updateLimitSwapPair({
        inputToken: defaultInputToken,
        outputToken: defaultOutputToken
      }));
    }
  }, [chainId, tokens, inputToken === null || inputToken === void 0 ? void 0 : inputToken.chainId, outputToken === null || outputToken === void 0 ? void 0 : outputToken.chainId, inputToken === null || inputToken === void 0 ? void 0 : inputToken.address, outputToken === null || outputToken === void 0 ? void 0 : outputToken.address, JSON.stringify(defaultPair)]);
  return null;
};
export var DexUpdater = () => {
  var chainId = useUpdaterChainId();
  var dispatch = useAppDispatch();
  var isLimitOrderRouter = useLimitOrderRouter();
  var updateSpotSwapTokens = useSpotSwapTokensUpdater();
  var updateLimitSwapTokens = useLimitSwapTokensUpdater();
  var updateTokenlonV6Config = useTokenlonV6ConfigUpdater();
  var updateSpotSwapRecommendTokens = useSpotSwapRecommendTokensUpdater();
  useEffect(() => {
    if (!chainId) return;

    if (isLimitOrderRouter) {
      updateLimitSwapTokens();
    } else {
      updateSpotSwapTokens();
      updateSpotSwapRecommendTokens();
    }

    exchangeRpc.getMobileAppConfig().then(config => {
      dispatch(updateConfig({
        chainId,
        config
      }));
    });
    updateTokenlonV6Config().then(config => {
      dispatch(updateV6Config({
        v6Config: config
      }));
    });
  }, [chainId, updateSpotSwapTokens, updateSpotSwapRecommendTokens, updateLimitSwapTokens, isLimitOrderRouter]);
  return __jsx(React.Fragment, null, __jsx(SpotSwapPairResetUpdater, null), __jsx(LimitSwapPairResetUpdater, null));
};
export default DexUpdater;