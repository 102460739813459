import _taggedTemplateLiteral from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from 'styled-components';
var levelColors = {
  info: 'alertInfo',
  warning: 'alertWarning',
  risk: 'alertRisk',
  verified: 'alertVerified'
};
export var StyledBar = styled.div.withConfig({
  displayName: "StyledBar",
  componentId: "sc-1emmdy-0"
})(["display:flex;justify-content:space-between;align-items:flex-start;background:", ";border-radius:8px;padding:16px 20px;width:100%;color:", ";transition:opacity 0.3s ease-in-out;", ""], _ref => {
  var level = _ref.level,
      theme = _ref.theme;
  return theme.colors["".concat(levelColors[level], "Bg")];
}, _ref2 => {
  var level = _ref2.level,
      theme = _ref2.theme;
  return theme.colors["".concat(levelColors[level], "Text")];
}, _ref3 => {
  var theme = _ref3.theme;
  return theme.media.sm(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    padding: 8px 12px;\n  "])));
});
export var StyledTitleWrapper = styled.div.withConfig({
  displayName: "StyledTitleWrapper",
  componentId: "sc-1emmdy-1"
})(["display:flex;align-items:flex-start;gap:8px;font-size:14px;font-weight:400;line-height:17px;"]);
export var StyledLink = styled.a.withConfig({
  displayName: "StyledLink",
  componentId: "sc-1emmdy-2"
})(["color:#777a8c;font-size:12px;font-weight:500;cursor:pointer;:hover{color:", ";}"], _ref4 => {
  var theme = _ref4.theme;
  return theme.colors.primary;
});
export var StyledIcon = styled.img.withConfig({
  displayName: "StyledIcon",
  componentId: "sc-1emmdy-3"
})(["width:16px;height:16px;"]);
export var StyledCloseIcon = styled(StyledIcon).withConfig({
  displayName: "StyledCloseIcon",
  componentId: "sc-1emmdy-4"
})(["cursor:pointer;"]);
export var StyledWarningCheck = styled.label.withConfig({
  displayName: "StyledWarningCheck",
  componentId: "sc-1emmdy-5"
})(["display:flex;align-items:center;cursor:pointer;input[type='checkbox']{opacity:0;cursor:pointer;height:0;width:0;}input:checked ~ .checkmark{background-color:", ";}.checkmark{width:18px;height:18px;position:relative;background:#fafbfc;border:1px solid #eaecf5;border-radius:4px;&::after{content:'';position:absolute;left:5px;top:2px;display:block;width:5.7px;height:10.5px;border:solid white;border-width:0 2px 2px 0;transform:rotate(45deg);}}"], _ref5 => {
  var level = _ref5.level,
      theme = _ref5.theme;
  return theme.colors["".concat(levelColors[level], "Text")];
});